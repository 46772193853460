<template>
    <div id="preview">
      <v-card>
        <v-app-bar
          color="primary"
          dense
          dark
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
        >
          <v-toolbar-title>
            {{ pageTitle }}
          </v-toolbar-title>
  
          <v-spacer></v-spacer>
  
          <v-btn icon dark @click="closePrompt">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
  
        <v-form ref="form1" v-model="valid1" lazy-validation>
          <v-card-text>
            <h5><span class="text-danger">*</span> indicates required field</h5>
          </v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="4">
                <h5><span class="text-danger">*</span> Date</h5>
                <v-menu
                  :rules="ReInvitedDateRules"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  required
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="ReInvitedDate"
                      readonly
                      v-on="on"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ReInvitedDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <h5><span class="text-danger">*</span> Select Member</h5>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="MemberIdOptionsLoading"
                  :items="MemberIdOptions"
                  :rules="MemberIdRules"
                  v-model="MemberId"
                  dense
                  clearable
                  outlined
                  multiple
                  chips
                  deletable-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid1"
              @click.prevent="confirmAlert"
              :loading="SubmitFlag"
              elevation="30"
              shaped
              tile
              small
              color="#1db954"
              class="font-size-h6 px-10 mr-3 white--text"
            >
              Save
            </v-btn>
            <v-btn
              @click.prevent="closePrompt"
              elevation="30"
              shaped
              tile
              small
              color="#a52a2a"
              class="font-size-h6 px-10 mr-3 white--text"
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </template>
      
      <script>
  import common from "@/view/Common.vue";
  import companyConfig from "@/company_config.json";
  import Swal from "sweetalert2";
  
  export default {
    mixins: [common],
    components: {},
    props: {
      addRecordPrompt: {
        type: Boolean,
        required: true,
      },
      pageTitle: {
        type: String,
      },
      pageDescription: {
        type: String,
      },
      recordData: {
        type: Array,
        required: true,
      },
      LomCode: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        valid1: true,
        valid2: true,
        LoadingFlag: false,
        SearchFlag: false,
        SubmitFlag: false,
        menu1: false,
        ReInvitedDate: "",
        ResultFlag: false,
        ReInvitedDateRules: [(v) => !!v || "ReInvitedDate is required"],
        rows: [],
  
        MemberIdOptionsLoading: false,
        MemberIdOptions: [],
        MemberId: [],
      };
    },
    computed: {},
    watch: {
      PageInfo: function () {
        console.log("watch PageInfo");
        this.LoadingFlag = false;
        var tr = this.PageInfo;
        console.log({ tr });
        var n1 = Object.keys(tr).length;
        console.log({ n1 });
        if (n1 > 0) {
          this.PageTitle = tr.PageTitle;
          this.PageDescription = tr.PageDescription;
  
          var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
          console.log({ CurrentJciYearId });
          this.JciYearCode = CurrentJciYearId;
  
          var YearName = parseInt(tr.CurrentJciYearName);
          console.log({ YearName });
          this.YearName = YearName;
  
          var NextJciYearId = parseInt(tr.NextJciYearId);
          console.log({ NextJciYearId });
          this.NextYearCode = NextJciYearId;
  
          var NextJciYearName = parseInt(tr.NextJciYearName);
          console.log({ NextJciYearName });
          this.NextYearName = NextJciYearName;
  
          this.CurrentYearId = NextJciYearId;
  
          var LomId = this.$session.get("LomId");
          LomId = LomId == (null || undefined) ? "" : LomId;
          this.LomCode = LomId;
        }
      },
      MemberIdOptions: function () {
        this.MemberIdOptionsLoading = false;
      },
      MemberId: function () {
        console.log("watch MemberId");
        var temp_code = this.MemberId;
        console.log("temp_code=" + temp_code);
        var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
        console.log("idx=" + idx);
        if (idx >= 0) {
          var member = this.MemberIdOptions[idx];
          console.log({ member });
        }
      },
    },
    created() {},
    methods: {
      refreshPageData() {
        console.log("refreshPageData called");
  
        console.log("refreshPageData called");
        this.LoadingFlag = true;
        var url1 = "api/menu-details/show";
        var condition1 = {
          UserInterface: 1,
          Module: "mol_phase4",
          Action: "create",
        };
        this.getPageDetails("PageInfo", {}, url1, condition1);
        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);
  
        this.ReInvitedDate = todayDate;
        this.getMemberIdOptions();
      },
      resetForm() {
        console.log("resetForm called");
        this.$refs.form1.reset();
      },
      closePrompt() {
        console.log("closePrompt called");
        console.log(this.ResultFlag);
        this.$emit("hideAddRecordPrompt", this.ResultFlag);
      },
      getMemberIdOptions() {
        console.log("getMemberIdOptions called");
        this.MemberIdOptionsLoading = true;
        var selectbox1_source = "MemberId";
        var selectbox1_destination = "MemberIdOptions";
        var selectbox1_url = "api/mission-onelakh/mol-member-option";
        var selectbox1_conditions_array = {
          UserInterface: 2,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      },
      confirmAlert() {
        var validate1 = this.$refs.form1.validate();
        console.log("validate1=" + validate1);
        if (validate1) {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Save`,
            confirmButtonColor: "#3085d6",
            cancelButtonText: `Cancel`,
            cancelButtonColor: "#d33",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.submitForm();
            } else {
              this.sweetAlert("error", "You cancelled the process", true);
            }
          });
        } else {
          this.toast("error", "Kindly fill the required fields");
        }
      },
      submitForm() {
        console.log("submitForm is called");
  
        var validate1 = this.$refs.form1.validate();
        console.log("validate1=" + validate1);
  
        //   validate1 = false;
  
        if (validate1) {
          this.SubmitFlag = true;
  
          var server_url = companyConfig.apiURL;
          var token = this.$session.get("token");
          token = token == (null || undefined) ? 0 : token;
          var add_url = server_url + "api/mission-onelakh/mol-member-update";
          var upload = {
            UserInterface: 4,
            Member: this.MemberId,
            ReInvitedDate: this.ReInvitedDate,
          };
          console.log("upload=" + JSON.stringify(upload));
          console.log(
            "server_url=" +
              server_url +
              ", token=" +
              token +
              ", add_url=" +
              add_url
          );
  
          const thisIns = this;
          var output = "";
          var records = "";
          var successTxt = "";
          var errorTxt = "";
          var flag = 0;
  
          this.$http({
            url: add_url,
            method: "POST",
            data: upload,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
            .then(function (response) {
              console.log("response=" + JSON.stringify(response));
  
              thisIns.SubmitFlag = false;
  
              output = response.data.output;
              flag = response.data.flag;
              output = response.data.output;
              records = response.data.records;
              console.log("output=" + output + ", flag=" + flag);
              successTxt = response.data.success;
              errorTxt = response.data.error;
              console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);
  
              if (flag == 1) {
                thisIns.ResultFlag = true;
                thisIns.resetForm();
                thisIns.closePrompt();
                thisIns.sweetAlert("success", output, true);
                this.toast("success", output);
              } else {
                thisIns.sweetAlert("error", output, false);
              }
            })
            .catch(function (error) {
              console.log("error=" + error);
              thisIns.SubmitFlag = false;
            });
        } else {
          var message = "";
          if (!validate1) {
            message += "Kindly fill the required fields";
          }
          thisIns.sweetAlert("error", message, false);
        }
      },
    },
    beforeMount() {
      this.refreshPageData();
    },
  };
  </script>
    